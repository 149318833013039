import React, { useEffect, useState } from "react";
 import * as Styles from "../style/home.module.scss"

import mail from "../assets/images/Mail.png";
import mobile from "../assets/images/Mobile.png";
import twitter from "../assets/images/Twitter.png";
import Location from "../assets/images/Location.png";
import Contactusimage from "../assets/images/Contact_Us_BG-Gradient.png";
import axios from "../utils/axios-config"
const Contactus = () => {
    const intialvalues = { Firstname: "", email: "", PhoneNumber: "", message: "" }
    const [formValues, setFormValues] = useState(intialvalues)
    const [formErrors, setFormErrors] = useState({})
    const [issubmit, setIssubmit] = useState(false)
    const handlechange = (e) => {
        const { name, value } = e.target
        setFormValues({ ...formValues, [name]: value });
        setIssubmit(false)
    }
    const submithandler = (e) => {
        e.preventDefault();
        setFormErrors(validate(formValues))
        console.log(formValues)


        if (formValues.Firstname !== "" && formValues.email !== "" && formValues.PhoneNumber !== "") {
            var formData = { name: formValues.Firstname, phone: formValues.PhoneNumber, email: formValues.email, message: formValues.message }
            axios.post('/contact', formData)
                .then(response => {
                    setIssubmit(true)
                    setFormValues(intialvalues);
                })
                .catch((error) => {
                    if (error) {
                        //context.setLoadingMask(false)
                        // if (error.response) {
                        //     setMsg(error.response.data.message)
                        // }
                    }
                });

            //alert("Thank you for your details. we will get back to you shortly")
        }
        else {
            alert("Please fill the all feilds")
        }

    }
    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && issubmit) {
        }
    }, [formErrors])
    const validate = (values) => {
        const errors = {};
        const regex = /^[^s@]+@[^\s@]+\.[^\s@]{2,}$/i;
        const regexPhone = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        
        if (!values.Firstname) {
            errors.Firstname = "Name is Required"
        }
        if (!values.email) {
            errors.email = "Email is Required"
        }
        else if (regex.test(values.email)) {
            errors.email = "This is not a valid format"
        }

        if (!values.PhoneNumber) {
            errors.PhoneNumber = " PhoneNumber is Required"
        }
        else if (!regexPhone.test(values.PhoneNumber)) {
            errors.PhoneNumber = "PhoneNumber must contain 10 char!!"
        }

        return errors;
    };
    return (
        <div>
            <div className={Styles.Contactheading}>
                <h2 className={Styles.Contactourteam}>Contact Our Team</h2>
                <p className={Styles.topcontent}>We're happy to answer questions and get you acquainted with Skill Elevator</p>
                {/* <p className={Styles.samecontent}> with Skill Elevator</p> */}
            </div>
            <div className={Styles.parent} >

                <div className={Styles.child1}>

                    <form onSubmit={submithandler} className={Styles.forms}>
                        {issubmit && <span style={{ fontSize: "18px", color: "green", fontWeight: "bold" }}>Thank you for your details. <br />we will get back to you shortly.</span>}
                        <h2 className={Styles.getintouch}>Get In Touch</h2>

                        <div>
                            <input
                                type="FirstName"
                                name="Firstname"
                                autoComplete="off"
                                placeholder=" Name"
                                value={formValues.Firstname}
                                onChange={handlechange}
                                className={Styles.btns}
                            />
                        </div>
                        <p>{formErrors.Firstname}</p>
                        <p>{formErrors.LastName}</p>

                        <div>
                            <input
                                type="email"
                                name="email"
                                autoComplete="off"
                                placeholder=" Enter email"
                                value={formValues.email}
                                className={Styles.btns}
                                onChange={handlechange}
                            />
                        </div>
                        <p>{formErrors.email}</p>
                        <div>
                            <input type="phoneNumber"
                                name="PhoneNumber"
                                autoComplete="off"
                                placeholder="Phone Number"
                                value={formValues.PhoneNumber}
                                onChange={handlechange}
                                className={Styles.btns}
                            />
                            <p>{formErrors.PhoneNumber}</p>
                            <div>
                                <input type="message"
                                    name="message"
                                    autoComplete="off"
                                    placeholder="Message"
                                    value={formValues.message}
                                    onChange={handlechange}
                                    className={Styles.btns}
                                />
                            </div>
                            <p className={Styles.paragarph}>Your information is secure. We won't ever send you spam or sell your contact details. We hate BS like that as much as you do.</p>

                            <button type="submit" className={Styles.submitbtn}>SIGNUP FOR FREE</button>

                        </div>

                    </form>
                </div>
                <div className={Styles.child2} >
                    <div className={Styles.child3}>
                        <h2 className={Styles.headingtag}>Contact us</h2>
                        <div className={Styles.icons}>
                            <img src={mail} alt="mail" className={Styles.images} />
                            <p>info@skillelevator.com</p>
                        </div>
                        <hr className={Styles.horizantaline} />
                        {/* <div className={Styles.icons}>
                        <img src={mobile} alt="Mobile" className={Styles.images} />
                        <p>+91 77952 12345</p>
                    </div>
                    <hr  className={Styles.horizantaline}/> */}
                        <div className={Styles.icons}>
                            <img src={twitter} alt="twitter" className={Styles.images} />
                            <p>@skillelevator</p>
                        </div>
                        <hr className={Styles.horizantaline} />
                        <div className={Styles.icons}>
                            <img src={Location} alr="location" className={Styles.images} />
                            <p className={Styles.location}>44, S End Rd, above SuccessEdge Academy, Tata Silk Farm, Basavanagudi, Bengaluru, Karnataka 560004</p>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    )
}
export default Contactus;